<script>
  import $ from "jquery";
  import appConfig from "@/app.config";
  import RetailModal from "./RetailerModal";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  export default {
    name: "account-settings",
    page: {
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
      RetailModal,
    },
    data() {
      return {};
    },
    methods: {
      openModal() {
        let element = this.$refs.retailModal.$el;
        $(element).modal("show");
      },
    },
    mounted() {
      // Analytics
    },
    computed: {
      userFrancophone() {
        const user = storage.getSavedState("currentUser");
        return user.country === "CI" || user.country === "SN";
      },
    },
  };
</script>
<style scoped lang="scss">
  .mnotify-card-body {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    height: calc(100vh - 50px);
  }

  #become_a_retailer {
    width: 100%;
    height: 52px;
    background: #f3f3f4;
    border: 1px solid #d4d8e2;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2em;
    padding-right: 0.3em;
    margin-top: 10em;

    ._retailer_text {
      font-style: normal;
      font-weight: 500;
      font-size: min(1.5vw, 16px);
      line-height: 159.3%;
      display: flex;
      align-items: center;
      color: #484a4f;
    }

    ._retailer_btn {
      button {
        width: 100%;
        height: 40px;
        background: #d4d8e2;
        border-radius: 5px;
        outline: none;
        border: none;
        padding-right: 2em;
        padding-left: 2em;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        text-transform: capitalize;
        color: #484a4f;
      }
    }
  }

  #account-settings {
    height: calc(100vh);
  }

  ul {
    margin: 0px !important;
    padding: 0px !important;
  }

  ul li {
    list-style: none;
  }

  ul li h3 {
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 30px;
    color: #484a4f;
    margin-bottom: 0px !important;
  }

  ul li div {
    margin-top: 24px;
  }

  ul li p {
    margin-bottom: 0px !important;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #979797;
  }

  a {
    color: normal;
    text-decoration: none;
  }

  .become-a-reseller {
    background-color: #ff8f00 !important;
    color: white !important;
  }

  @media screen and (max-width: 500px) {
    #become_a_retailer {
      flex-wrap: wrap;
      height: 100%;
    }

    ._retailer_text {
      font-size: 12px !important;
    }
  }
</style>
<template>
  <div class="col-md-12">
    <div id="account-settings" class="mnotify-card">
      <div id="side-indicators-content" class="mnotify-card-header">
        <h3 class="mnotify-card-header-text py-0 my-0">
          {{ $t("dashboard.account_settings.header") }}
        </h3>
      </div>
      <div class="mnotify-card-body">
        <div class="billing-card-inner">
          <ul>
            <li>
              <router-link to="/change-password">
                <div>
                  <h3 class="header">
                    {{ $t("dashboard.account_settings.change_password.title") }}
                    <span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt="" />
                    </span>
                  </h3>
                  <p class="text">
                    {{
                      $t(
                        "dashboard.account_settings.change_password.sub_header"
                      )
                    }}
                  </p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/change-phone-number">
                <div>
                  <h3 class="header">
                    {{ $t("dashboard.account_settings.change_number.title") }}
                    <span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt=""
                    /></span>
                  </h3>
                  <p class="text">
                    {{
                      $t("dashboard.account_settings.change_number.sub_text")
                    }}
                  </p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/change-email">
                <div>
                  <h3 class="header">
                    {{ $t("dashboard.account_settings.change_email.title") }}
                    <span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt=""
                    /></span>
                  </h3>
                  <p class="text">
                    {{
                      $t("dashboard.account_settings.change_email.sub_header")
                    }}
                  </p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/email-and-notification">
                <div>
                  <h3 class="header">
                    {{
                      $t(
                        "dashboard.account_settings.sms_wallet_notification.title"
                      )
                    }}

                    <span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt=""
                    /></span>
                  </h3>
                  <p class="text">
                    {{
                      $t(
                        "dashboard.account_settings.sms_wallet_notification.sub_header"
                      )
                    }}
                  </p>
                </div>
              </router-link>
            </li>
            <li >
              <router-link to="/referral-settings">
                <div>
                  <h3 class="header">
                    {{ $t("dashboard.account_settings.referral_code.title")
                    }}<span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt=""
                    /></span>
                  </h3>
                  <p class="text">
                    {{
                      $t("dashboard.account_settings.referral_code.sub_header")
                    }}
                  </p>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/default-account-settings">
                <div>
                  <h3 class="header">
                    {{
                      $t("dashboard.account_settings.account_defaults.defaults")
                    }}
                    <span style="float: right">
                      <img
                        height="20"
                        src="@/assets/right-arrow (1).svg"
                        alt=""
                    /></span>
                  </h3>
                  <p class="text">
                    {{
                      $t(
                        "dashboard.account_settings.account_defaults.change_defaults"
                      )
                    }}
                  </p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- 
                                                    END THE PAGE
                                                -->
        <div id="become_a_retailer">
          <div class="_retailer_text">
            {{ $t("dashboard.account_settings.reseller.earn") }}
          </div>
          <div class="_retailer_btn">
            <button @click="openModal" class="become-a-reseller">
              {{ $t("dashboard.account_settings.reseller.become_reseller") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <RetailModal ref="retailModal" />
  </div>
</template>
